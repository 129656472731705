import { Drawer } from "components/Drawer/Drawer"
import { Icon, IconType } from "components/Icon"
import Link from "components/Link"
import { t } from "locales"
import DrawerTool from "modules/article/components/Tools/DrawerTool"
import { ReactNode } from "react"

import Grid from "@mui/material/Grid"
import MenuItem from "@mui/material/MenuItem"
import Typography from "@mui/material/Typography"

import { ILinkList } from "data/contentData/api/content"

interface IProps {
    lists: ILinkList[] | undefined
    open: boolean
    onClose: () => void
    regionalContentId?: string
}

export default function DrawerTools({
    lists,
    onClose,
    open,
    regionalContentId
}: IProps) {
    return (
        <Drawer
            header={
                <MenuItem
                    component="div"
                    disabled>
                    <Typography
                        align="center"
                        color="inherit"
                        variant="subtitle2">
                        {t.tools.title}
                    </Typography>
                </MenuItem>
            }
            position="right"
            open={open}
            onClose={onClose}>
            {regionalContentId ? (
                <DrawerContent
                    icon="regional"
                    title={t["tools"]["regional-content"]["title"]}>
                    <Typography variant="body2">
                        {t["tools"]["regional-content"]["content"]}
                    </Typography>
                    <Link
                        color="secondary"
                        href={`#${regionalContentId}`}
                        variant="body2">
                        {t["tools"]["regional-content"]["action"]}
                    </Link>
                </DrawerContent>
            ) : null}
            {lists?.map((list, index) => (
                <DrawerTool
                    expanded={index < 2}
                    key={list.name}
                    onClose={onClose}
                    list={list}
                />
            ))}
        </Drawer>
    )
}

type DrawerContentProps = {
    icon?: IconType
    title: string
    children: ReactNode
}

function DrawerContent(props: DrawerContentProps) {
    const { icon, title, children } = props

    return (
        <Grid
            container
            sx={{ marginBottom: 2 }}>
            <Grid
                item
                sx={{ display: "flex", padding: 2 }}>
                {icon ? <Icon name={icon} /> : null}
                <Typography
                    variant="body1"
                    sx={{ paddingLeft: 1, fontWeight: 700 }}>
                    {title}
                </Typography>
            </Grid>
            <Grid
                item
                sx={{ py: 1, px: 2 }}>
                {children}
            </Grid>
        </Grid>
    )
}
