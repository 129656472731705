import { Icon } from "components/Icon"
import { RevisedOrNewBadge } from "components/RevisedOrNewBadge"
import { Badge } from "components/UI/Badge"
import useUser from "hooks/useUser"
import { t } from "locales"
import SitelistLink from "modules/Sitelist/SitelistLink"

import ListItem from "@mui/material/ListItem"

import { IPage } from "data/contentData/api/content"
import { isEditor } from "lib/auth"
import { isOutdated } from "lib/cms/is-outdated"
import { isPublished } from "lib/cms/is-published"

export default function SitelistItem(page: IPage) {
    const {
        labels,
        url,
        shortcut,
        name,
        revised,
        published,
        unpublished,
        revisionInterval,
        hasImportantUpdateUntil
    } = page
    const { user } = useUser()

    const _isEditor = isEditor(user?.roles)
    const outdated = _isEditor
        ? isOutdated(revisionInterval, published, revised)
        : false

    if (!isPublished(published, unpublished)) {
        return null
    }

    return (
        <ListItem
            key={url}
            disableGutters>
            <SitelistLink
                name={name}
                href={shortcut ?? url}
                isOutdated={outdated && _isEditor}
            />

            <RevisedOrNewBadge
                content={t.search.revised}
                date={revised}
            />
            <RevisedOrNewBadge
                content={t.search.new}
                date={published}
            />

            {labels?.map((label: string) => (
                <Badge
                    key={label}
                    title={label}>
                    {label}
                </Badge>
            ))}
            {hasImportantUpdateUntil && (
                <Badge title={t["important-updates"]["important-update"]}>
                    <Icon
                        name="important-update"
                        fontSize="inherit"
                    />
                </Badge>
            )}
        </ListItem>
    )
}
