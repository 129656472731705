/* eslint-disable i18next/no-literal-string */
import InteractiveJSON from "components/Debug/InteractiveJson"
import { Icon } from "components/Icon"
import { useInteractions } from "modules/interactions/InteractionsContext"
import { useSnackbar } from "notistack"
import { useEffect, useState } from "react"

import Button from "@mui/material/Button"
import Drawer from "@mui/material/Drawer"
import IconButton from "@mui/material/IconButton"
import Link from "@mui/material/Link"
import List from "@mui/material/List"
import ListItem from "@mui/material/ListItem"
import ListItemText from "@mui/material/ListItemText"
import { grey } from "@mui/material/colors"
import { styled } from "@mui/material/styles"

import { IPage } from "data/contentData/api/content"
import { useRefreshPage } from "data/internal/hooks/refresh-page.hooks"
import { isPublished } from "lib/cms/is-published"

export default function Debug({ page }: { page: IPage }) {
    const { showDebug, closeDebug } = useInteractions()

    const pageJSON = JSON.stringify(page, null, 2)

    return (
        <Drawer
            open={showDebug}
            anchor="bottom"
            variant={showDebug ? "persistent" : "temporary"}>
            <DrawerHeader
                page={page}
                handleCloseDrawer={closeDebug}
            />
            <DrawerContent>
                <DrawerList page={page} />
                <Pre json={pageJSON} />
            </DrawerContent>
        </Drawer>
    )
}

function Pre({ json }: { json: string }) {
    const [showInteractive, setShowInteractive] = useState(true)

    const toggleShowInteractive = () => setShowInteractive(p => !p)

    return (
        <div style={{ position: "relative" }}>
            <JSONButtonsWrapper>
                <CopyButton data={json} />
                <IconButton
                    sx={{
                        backgroundColor: showInteractive ? grey[300] : "initial"
                    }}
                    onClick={toggleShowInteractive}>
                    <Icon name="code" />
                </IconButton>
            </JSONButtonsWrapper>
            {showInteractive ? (
                <StyledPre>
                    <InteractiveJSON json={json} />
                </StyledPre>
            ) : (
                <StyledPre>{json}</StyledPre>
            )}
        </div>
    )
}

function DrawerHeader({
    page,
    handleCloseDrawer
}: {
    page: IPage
    handleCloseDrawer: () => void
}) {
    const domainArray = process.env.NEXT_PUBLIC_ID.split("-")
    const rootDomain =
        process.env.NODE_ENV === "development" ? "dev" : domainArray.at(-1)

    const domain = domainArray.slice(0, -1).join(".")
    const cmsUrl = `https://cms.${domain}.${rootDomain}/editor/edit/${page.contentId}`
    const prodUrl = domainArray.join(".")

    const { enqueueSnackbar } = useSnackbar()
    const {
        mutate: refreshPage,
        isSuccess,
        isError,
        isLoading
    } = useRefreshPage()

    useEffect(() => {
        if (isSuccess) {
            enqueueSnackbar("Page refreshed", { variant: "success" })
        } else if (isError) {
            enqueueSnackbar("Failed to refresh page", { variant: "error" })
        }
    }, [isSuccess, isError, enqueueSnackbar])

    const handleClick = async () => {
        refreshPage({ path: page.url })
        if (isLoading) {
            enqueueSnackbar("Refreshing page...", { variant: "info" })
        }
    }

    return (
        <StyledDrawerHeader>
            <Button
                component={Link}
                onClick={handleClick}
                startIcon={<Icon name="update" />}>
                Revalidate
            </Button>
            <Button
                component={Link}
                href={process.env.NEXT_PUBLIC_CONTENT_API}
                target="_blank"
                startIcon={<Icon name="storage" />}>
                API
            </Button>
            <Button
                component={Link}
                href="https://sentry.nhi.no/organizations/nhi/issues/?project=8&statsPeriod=7d"
                target="_blank"
                startIcon={<Icon name="reportProblem" />}>
                Sentry
            </Button>
            <Button
                component={Link}
                href={cmsUrl}
                target="_blank"
                startIcon={<Icon name="settings" />}>
                CMS
            </Button>
            <Button
                component={Link}
                href={`https://${prodUrl}${page.url}`}
                target="_blank"
                startIcon={<Icon name="language" />}>
                {prodUrl}
            </Button>
            <IconButton
                size="large"
                onClick={handleCloseDrawer}>
                <Icon name="close" />
            </IconButton>
        </StyledDrawerHeader>
    )
}

function DrawerList({ page }: { page: IPage }) {
    const pageJSON = JSON.stringify(page, null, 2)
    const kbSize = (pageJSON.length / 1024).toFixed(2)
    const now = `${new Date().toISOString()} (${Date.now()})`
    const isPublishedString = isPublished(page.published, page.unpublished)
        ? "Yes"
        : "No"

    return (
        <List dense>
            <StyledListItem>
                <ListItemText
                    primary={page.url}
                    secondary="Path"
                />
            </StyledListItem>
            <StyledListItem>
                <ListItemText
                    primary={now}
                    secondary="Now"
                />
            </StyledListItem>
            <StyledListItem>
                <ListItemText
                    primary={isPublishedString}
                    secondary="isPublished(...)"
                />
            </StyledListItem>
            <StyledListItem>
                <ListItemText
                    primary={page.types.join(".")}
                    secondary="Types"
                />
            </StyledListItem>
            <StyledListItem>
                <ListItemText
                    primary={`${page.name} (${kbSize} kb)`}
                    secondary="Name (size)"
                />
            </StyledListItem>
            <StyledListItem>
                <ListItemText
                    primary={page.published}
                    secondary="Published"
                />
            </StyledListItem>
        </List>
    )
}

const StyledDrawerHeader = styled("div")(({ theme }) => ({
    display: "flex",
    gap: theme.spacing(2),
    alignItems: "center",
    justifyContent: "flex-end",
    padding: theme.spacing(0, 2),
    backgroundColor: grey[200],
    // necessary for content to be below app bar
    ...theme.mixins.toolbar
}))

const DrawerContent = styled("div")(({ theme }) => ({
    display: "grid",
    gridTemplateColumns: "1fr 2fr",
    gap: theme.spacing(2),
    padding: theme.spacing(2)
}))

const StyledPre = styled("pre")(({ theme }) => ({
    whiteSpace: "pre-wrap",
    wordBreak: "break-all",
    margin: 0,
    padding: theme.spacing(2),
    fontSize: "0.8rem",
    height: "50vh",
    overflow: "auto",
    backgroundColor: grey[100]
}))

const StyledListItem = styled(ListItem)(() => ({
    borderBottom: `1px solid ${grey[300]}`
}))

function CopyButton({ data }: { data: string }) {
    const [finishedCopy, setFinishedCopy] = useState(false)

    const copyPageData = () => {
        setFinishedCopy(false)

        navigator.clipboard
            .writeText(data)
            .then(() => {
                setFinishedCopy(true)
                setTimeout(() => {
                    setFinishedCopy(false)
                }, 3000)
            })
            .catch(_ => {
                setFinishedCopy(true)
            })
    }

    return (
        <IconButton onClick={copyPageData}>
            {finishedCopy ? <Icon name="check" /> : <Icon name="contentCopy" />}
        </IconButton>
    )
}

const JSONButtonsWrapper = styled("div")(({ theme }) => ({
    position: "absolute",
    display: "flex",
    gap: theme.spacing(1),
    top: theme.spacing(2),
    right: theme.spacing(4)
}))
